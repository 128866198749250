import { useLazyQuery, ApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import { UsedSca } from "../../../graphql/graphql_information/sca/types/UsedSca";
import { USED_SCA } from "../../../graphql/graphql_information/sca/query";

const useSCA = (client: ApolloClient<any>) => {
  const [used, setUsed] = useState(false);
  const [checkUsedSca] = useLazyQuery<UsedSca>(USED_SCA, {
    fetchPolicy: "network-only",
    client,
    onCompleted: (data) => {
      setUsed(!!data?.usedSca);
    },
    onError: () => {
      setUsed(true);
    },
  });

  useEffect(() => {
    checkUsedSca();
  }, []);

  return { used };
};

export default useSCA;
