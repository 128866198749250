export enum CARD_STATUS {
  CREATING = "CREATING",
  AWAITING_ACTIVATION = "AWAITING_ACTIVATION",
  ACTIVATING = "ACTIVATING",
  REACTIVATING = "REACTIVATING",
  ACTIVE = "ACTIVE",
  SUSPENDING = "SUSPENDING",
  SUSPENDED = "SUSPENDED",
  CLOSING = "CLOSING",
  CLOSED = "CLOSED",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
}

export enum CARD_RULES {
  DAILY_MAX_SPEND = "DAILY_MAX_SPEND",
  MONTHLY_MAX_SPEND = "MONTHLY_MAX_SPEND",
  WEEKLY_MAX_SPEND = "WEEKLY_MAX_SPEND",
}

export enum CARD_OPERATOR {
  END_USER = "END_USER",
  PARTNER = "PARTNER",
  LINKCY = "LINKCY",
}

export enum CHOISE_CARD {
  DETAILS = "DETAILS",
  PIN = "PIN",
}

export enum CARD_SCHEME {
  MASTERCARD = "MASTERCARD",
  VISA = "VISA",
}
