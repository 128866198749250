import { Box, Typography } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hook";
import { useLazyQuery, useSubscription } from "@apollo/client";
import { CHECK_SCA } from "../../../graphql/graphql_information/sca/query";
import { informationClient } from "../../../apolloClient";
import CustomDialog from "../CustomDialog";
import { useIntl } from "react-intl";
import styles from "./styles";
import {
  AuthenticationChoise,
  ScaDeviceStatus,
} from "../../../enums/authentication";
import { CheckSca } from "../../../graphql/graphql_information/sca/types/CheckSca";
import { ON_VALIDATED_SCA } from "../../../graphql/graphql_information/sca/subscription";
import {
  OnVadidatedSca,
  OnVadidatedScaVariables,
} from "../../../graphql/graphql_information/sca/types/OnVadidatedSca";
import LinkStore from "./LinkStore";
import useSCA from "../../customHooks/sca/sca";

const Sca: FC = () => {
  const intl = useIntl();
  const classes = styles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    qrCode: "",
    joinCode: "",
  });
  const { corporate } = useAppSelector(
    (state) => state.information.myInformation,
  );

  const { used } = useSCA(informationClient);
  // check token if valid
  const [checkSca] = useLazyQuery<CheckSca>(CHECK_SCA, {
    client: informationClient,
    onCompleted: (data) => {
      if (data.checkSca) {
        const { isSet, joinCode, qrCode } = data.checkSca;
        if (!isSet && joinCode && qrCode) {
          setData({ joinCode, qrCode });
          setOpen(true);
        }
      }
    },
  });

  useSubscription<OnVadidatedSca, OnVadidatedScaVariables>(ON_VALIDATED_SCA, {
    variables: {
      corporateLinkcyId: corporate?.linkcyId,
    },
    client: informationClient,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data?.onVadidatedSca) {
        const { scaChoise, biometryStatus, pinStatus } = data.onVadidatedSca;
        const status =
          scaChoise === AuthenticationChoise.BIOMETRY
            ? biometryStatus
            : pinStatus;
        if (status === ScaDeviceStatus.SET) {
          handleClose();
        }
      }
    },
  });

  const checkSCA = async () => {
    if (
      used &&
      corporate &&
      ((!corporate?.biometryStatus && !corporate?.pinStatus) ||
        (corporate?.biometryStatus === ScaDeviceStatus.UNSET &&
          corporate?.pinStatus === ScaDeviceStatus.UNSET))
    )
      checkSca();
  };

  useEffect(() => {
    checkSCA();
  }, [checkSca, corporate]);

  const handleClose = () => {
    setData({ qrCode: "", joinCode: "" });
    setOpen(false);
  };

  return (
    <Box>
      <CustomDialog
        dialogTitle={intl.formatMessage({ id: "sca.title" })}
        open={open}
        handleClose={() => {}}
      >
        <Box className={classes.box}>
          <Box width={"65%"} margin={2}>
            <Typography gutterBottom className={classes.textContent}>
              {intl.formatMessage(
                { id: "sca.content.1" },
                {
                  text: (chunks) => (
                    <span className={classes.text}>{chunks}</span>
                  ),
                  code: () => <span className={classes.text}>{""}</span>,
                },
              )}
            </Typography>
            <div className={classes.code}>{data.joinCode}</div>
            <Typography gutterBottom className={classes.textContent}>
              {intl.formatMessage(
                { id: "sca.content.2" },
                {
                  text: (chunks) => (
                    <span className={classes.text}>{chunks}</span>
                  ),
                  code: () => <span className={classes.text}>{""}</span>,
                },
              )}
            </Typography>
            <LinkStore width={150} />
          </Box>

          <img
            src={`data:image/jpeg;base64,${data.qrCode}`}
            alt="QRCode"
            width={200}
          />
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default Sca;
