import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
  },
}));

interface ShowTransactionProps {
  type: string;
  price: string;
  color: string;
}

const ShowTransaction: FC<ShowTransactionProps> = (props) => {
  const { type, price, color } = props;
  const classes = useStyles({});
  return (
    <Box flex="1 0 auto">
      <Typography variant="h6">
        <FormattedMessage id={type} />
      </Typography>
      <Typography variant="h2" style={{ color }}>
        <span className={classes.bold}>{price} </span>
      </Typography>
    </Box>
  );
};
export default ShowTransaction;
