export default {
  "company.label.companyActivity": `Activité de l'entreprise`,
  "company.legalName.empty":
    "Veuillez renseigner la raison sociale de l'entreprise.",
  "company.legalForm.empty":
    "Veuillez renseigner la forme juridique de l'entreprise.",
  "company.legalRegistrationNumber.empty": "Veuillez renseigner SIRET.",
  "company.address.empty": `Veuillez renseigner l'adresse de la société.`,
  "company.postCode.empty": `Veuillez renseigner le code postal de la société.`,
  "company.city.empty": `Veuillez renseigner la ville où se trouve la société.`,
  "company.getInfo.error":
    "Erreur lors de la récupération des informations de la société.",
  "company.updateInfo.error":
    "Erreur lors de la mise à jour des informations de la société.",
  "company.update.success": "Entreprise mise à jour avec succès.",
  "company.getAccountInfo.error":
    "Erreur lors de la récupération de vos informations personnelles.",
  "company.label.beingCreated": "Votre compagnie est en cours de création.",
  "company.label.noSIRET": "Vous ne possédez pas de numéro SIRET.",
  "setting.iban": "IBAN",
  "setting.bic": "BIC",
  "setting.actualOffer": "Offre actuelle",
  "setting.getOfferError": `Erreur lors de la récupération des informations concernant votre offre.`,
  "setting.change.email": "Changer",
  "setting.offer.upgrade": "Mettre à jour",

  "setting.facture.title": `Vos Factures`,
  "setting.facture.link": `Téléchargez tout`,
  "facture.dialogue": `Factures`,
  "setting.download.facture.error":
    "Problème lors du téléchargement de facture",

  "setting.download.rib": "RIB",
  "setting.download.rib.error": "Problème lors du téléchargement du RIB",
  "setting.offer.actual": "Actuel",
  "setting.offers": "Forfaits",
  "setting.offers.details": "Détails des forfaits",
  "setting.dialog.email.title": `Changer mon adresse mail`,
  "setting.dialog.email.text":
    "Vous devez fournir un mot de passe pour changer votre mail",
  "password.not.match": "Veuillez insérer le bon mot de passe",

  "setting.text.changeMail": "Entrez votre nouveau mail pour le changer",
  "setting.email.empty": `Le champ email est requis`,
  "setting.email.notValid": `L'adresse mail est invalide`,

  "setting.dialog.offer.title": "Détails des offres",
  "setting.offer.details": "Détails",

  "offer.free": "Gratuit",

  "offer.servicePlus": "Service Plus",
  "offer.liveCard": "Live Card",
  "offer.smartDashboard": "Smart Dashboard",
  "offer.categorisationAuto": "Catégorisation Automatique",

  "offer.fonctionality": "Fonctionality",
  "offer.users": "Users",
  "offer.smartAccess": "Smart Access",
  "offer.encaissement": "collections",
  "offer.cheque": "Cheque",
  "offer.quantity": "Quantity",
  "offer.withdrawal": "Withdrawal",
  "offer.payinsAndpayouts": "Payins / Payout",
  "offer.cardTransaction": "Card transaction",
  "offer.virtual": "Virtual",
  "offer.physical": "Physical",
  "offer.businnesMastercard": "Business Mastercard",
  "offer.noLimit": "No limit",
  "offer.limitAtmWeek": "Withdrawal limit / week",
  "offer.limitPaymentMonth": "Payment limit / month",
  "offer.autorized": "Authorized",
  "offer.selected": "Selected",
  "offer.week": "week",
  "offer.month": "month",
  "offer.day": "day",
  "offer.payouts": "Payouts",
  "offer.payouts.inst": "Instant Transfers",
  "offer.normal": "Normal",
  "offer.inst": "Instant",
  "offer.direct.debits": "Direct Debits",
  "offer.limit.direct.debits": "Direct debit limit",
  "offer.limitTransactions": "Payment & transaction limit",
  "offer.autorized.monthly": "Authorized / month",
  "offer.autorized.daily": "Authorized / day",
  "offer.autorized.transaction": "Authorized / transfer",
  "offer.autorized.dd": "Authorized / direct debit",

  "setting.email.validationText":
    "Merci de renseigner le code de sécurité qui vous a été envoyé dans votre mail.",
  "setting.dialog.edit.title": "Modifier les informations de la société",

  "setting.edit.identity": "Identité",
  "setting.edit.coordinate": "Coordonnées",
  "setting.edit.informations": "Informations",
  "img.deposit1": "glissez déposez",
  "img.deposit2": "dans le cadre",
  "setting.import.image": `Veuillez survoler le cadre pour ajouter le logo`,
  "request.email.error": `Erreur lors de la vérification de l'adresse mail`,
  "change.email.error": `Erreur lors de la mise à jour de l'adresse mail`,
  "change.avatar.error": `Erreur lors de la mise à jour de le l'avatar`,

  "setting.company.cgs": `Conditions générales de services - Sapheer`,
  "setting.company.cgu": `Conditions générales d'utilisations - Cartes`,
  "setting.company.gtLinkcy": `Conditions générales d'utilisation - LinkCy`,
  "setting.company.cgsSapheer": `Conditions générales de services - Sapheer`,
  "setting.company.payment": `Conditions générales d'utilisations - Services de paiement`,
  "setting.company.confidentiality": `Politique de confidentialité`,

  "setting.title.contract": `Contrats`,
  "setting.title.confidentiality": `Données personnelles`,
  "setting.company.Pcc": ` Politique de confidentialité Contact`,

  "setting.company.footer": `Sapheer, une marque exploitée par l'entreprise Spark, propose des comptes et des cartes émis par Paynovate sous licence MasterCard et gérés par LinkCy SAS. Paynovate est autorisée par la Banque Nationale de Belgique en vertu de la loi sur la monnaie électronique et les institutions de monnaie électronique (réf. 0506763929) à émettre de la monnaie électronique et à fournir des services de paiement connexes. LinkCy SAS agit en tant qu'agent de Paynovate, enregistré auprès de la NBB sous le numéro 852295732.`,

  "setting.company.contact": "Contact",
};
