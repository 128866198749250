export default {
  "reset.title": "Reset",
  "reset.details": "Please enter your new password",
  "reset.back": "Return to ",
  "reset.link": "login",
  "reset.success": "Password update with success",
  "reset.link.notValid": "Link is not valid",
  "account.data.password.notValid":
    "Your password must contain at least one lowercase character, one uppercase character, one number and one special character.",
  "pwd.weak": "Weak",
  "pwd.fair": "Fair",
  "pwd.good": "Good",
  "pwd.strong": "Strong",
  "pwd.meter": "Password level :",
  "reset.password.notMatch": "Password don't match",
  "form.password.contentError":
    "Password would contain at least eight caracter, two letter uppercase, two  letter lowercase, two  number and two special caracter",
  "reset.invitation.not.accepted":
    "Before you can reset your password, please accept the invitation sent to your email address.",
};
