import {
  API_BANKING,
  API_CARD,
  API_TRANSACTION,
  API_URL,
  API_V2_URL,
} from "./config";
import {
  ApolloClient,
  createHttpLink,
  DocumentNode,
  InMemoryCache,
} from "@apollo/client";
import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { ACCESS_TOKEN_KEY } from "./services/LocalStorage";
import { GraphQLClient } from "graphql-request";
import { PatchedRequestInit } from "graphql-request/dist/types";

const convertHttpUrlToWs = (httpUrl?: string) => {
  if (!httpUrl) return "";
  return `${httpUrl
    .trim()
    .replace("http", "ws")
    .replace(/\/$/, "")}/subscriptions`;
};

const initAppoloClient = (httpUrl?: string, wsUrl?: string) => {
  const httpLink = createHttpLink({
    uri: httpUrl, // URL for HTTP requests
  });

  const wsLink = new WebSocketLink({
    uri: wsUrl ?? convertHttpUrlToWs(httpUrl), // Default WebSocket URL based on HTTP URL
    options: {
      reconnect: true, // Reconnect automatically
    },
  });

  const authLink = setContext((_, { headers }) => {
    // Get the authentication token from local storage or any other storage
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    // Return the headers to the context so httpLink and wsLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
};

export const informationClient = initAppoloClient(API_URL);
export const transactionClient = initAppoloClient(API_TRANSACTION);
export const bankingClient = initAppoloClient(API_BANKING);
export const cardClient = initAppoloClient(API_CARD);

type IFetcherWeb = <TVariables, TResponse>(
  query: DocumentNode,
  variables: TVariables,
  endpoint?: string | undefined,
  timeout?: number,
) => Promise<TResponse>;

export const fetcherWEB: IFetcherWeb = async (
  query,
  variables,
  _endpoint,
  timeout,
) => {
  const endpoint = _endpoint ? (_endpoint as string) : (API_V2_URL as string);
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  let option: PatchedRequestInit = {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  };
  if (timeout) {
    option = { ...option, timeout };
  }
  const graphQLClient = new GraphQLClient(endpoint, option);
  return graphQLClient.request(query, variables as any);
};
