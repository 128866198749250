import gql from "graphql-tag";

export const CHECK_SCA = gql`
  query CheckSca {
    checkSca {
      id
      isSet
      joinCode
      qrCode
    }
  }
`;

export const USED_SCA = gql`
  query UsedSca {
    usedSca
  }
`;
