import gql from "graphql-tag";

export const OFFERS_FRAGMENT = gql`
  fragment OffersInfo on Offer {
    id
    limitUser
    name
    payouts
    payoutsInst
    price
    virtualCard
    physicalCard
    ddMonthlyLimit
    ddTransactionLimit
    transactionDailyLimit
    transactionLimit
    transactionMonthlyLimit
    defaultSelected
  }
`;

export const UNDER_OFFER_FRAGMENT = gql`
  fragment UnderOfferInfo on UnderOffer {
    display
    id
    key
    nameEn
    nameFr
  }
`;

export const CONFIG_OFFER_FRAGMENT = gql`
  fragment ConfigOfferInfo on ConfigOffer {
    displays {
      ...UnderOfferInfo
    }
    id
    key
    nameEn
    nameFr
  }
  ${UNDER_OFFER_FRAGMENT}
`;
