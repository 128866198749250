import gql from "graphql-tag";

export const CORPORATE_FRAGMENT = gql`
  fragment CorporateInfo on Corporate {
    city
    employeesRange
    expectedMonthlyExpensesValue
    incorporationCode
    incorporationCountry
    incorporationDate
    industry
    isoCountryCode
    legalType
    postalCode
    registeredName
    street
    vatNumber
    signupProgression
    isIndividual
    linkcyId
    scaChoise
    pinStatus
    biometryStatus
    limited
    createCardPhysical
    createCardVirtual
  }
`;
